html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
    overflow-x: hidden;
}

.Page {
    min-height: 100vh;
    display: flex;
}

.App-header {
    background: linear-gradient(
            to bottom right,
            rgba(10, 219, 225, 1),
            rgba(10, 219, 225, 0) 50%
    ),
    linear-gradient(to bottom left, rgba(253, 143, 2, 1), rgba(253, 143, 2, 0) 50%),
    linear-gradient(to top, #ffffffff, #ffffff00);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-logo {
    height: 80vmin;
    max-width: 80%;
    pointer-events: none;
    filter: drop-shadow(10px 10px 30px rgba(255, 255, 255, 0.5)) drop-shadow(10px -10px 30px rgba(255, 255, 255, 0.5)) drop-shadow(-10px -10px 30px rgba(255, 255, 255, 0.5)) drop-shadow(-10px 10px 30px rgba(255, 255, 255, 0.5));
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: 3s ease-out Appear;
    }
}

@keyframes Appear {
    0% {
        opacity: 0;
        transform: scale(1.2, 1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.App-main {
    background: linear-gradient(#ffffff, #0adbe1 50%);
    align-items: center;
    display: flex;
}

.content {
    align-items: center;
}

.article {
    padding: 15px;
}

.App-footer {
    background: linear-gradient(#0adbe1, #fd8f02 100%);
    min-height: 600px;
    align-items: center;
    padding-top: 2rem;
    display: flex;
}

.debug {
    border: 1px solid red;
}

.m-l {
    margin-left: 0.25rem;
}

.m-r {
    margin-right: 0.25rem;
}